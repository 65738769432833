import React, { useState } from 'react';
import { Link } from 'gatsby';

const Navigation = ({ logo, isVacancy }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <nav className="navigation" role="navigation">
      <div className="navigation__container">
        <div className="navigation__left">
          <a className="navigation__branding" href="/">
            <span className="navigation__logo">
              <img src={logo} className="navigation__logo-src" alt="Camping Oud-Bommenede" />
            </span>
          </a>
        </div>
        <div className="navigation__links">
          <a className="navigation__link" href={ isVacancy ? '/' : '#home'}>Home</a>
          <a className="navigation__link" href={ isVacancy ? '/#camping' : '#camping'}>Camping</a>
          <a className="navigation__link" href={ isVacancy ? '/#omgeving' : '#omgeving'}>Omgeving</a>
          <a className="navigation__link" href={ isVacancy ? '/#vakantiewoningen' : '#vakantiewoningen'}>Vakantiewoningen</a>
          <a className="navigation__link" href={ isVacancy ? '/#contact' : '#contact'}>Contact</a>
        </div>
        <div id="menuToggle">
          <input type="checkbox" checked={menuOpen} onChange={() => toggleMenu()} />
          <span />
          <span />
          <span />
          <ul id="menu">
            <li><a className="navigation__link" onClick={() => toggleMenu()} href={ isVacancy ? '/' : '#home'}>Home</a></li>
            <li><a className="navigation__link" onClick={() => toggleMenu()} href={ isVacancy ? '/#camping' : '#camping'}>Camping</a></li>
            <li><a className="navigation__link" onClick={() => toggleMenu()} href={ isVacancy ? '/#omgeving' : '#omgeving'}>Omgeving</a></li>
            <li><a className="navigation__link" onClick={() => toggleMenu()} href={ isVacancy ? '/#vakantiewoningen' : '#vakantiewoningen'}>Vakantiewoningen</a></li>
            <li><a className="navigation__link" onClick={() => toggleMenu()} href={ isVacancy ? '/#contact' : '#contact'}>Contact</a></li>
            <li>
              <Link className="button button--primary" to={'/vakantiewoningen'}>
                Reserveer
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
};

export default Navigation;